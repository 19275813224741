<template>
  <div class="taskHall">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">任务中心</div>
      <div class="rightBtn"></div>
    </div>

    <div class="main">
      <div class="signInCard">
        <div class="signDesc">
          再签到
          <span>1</span>
          天，可获得{{ handNextPrizes() }}
        </div>
        <div class="signBox">
          <div class="checkInBox">
            <div class="signItem" :class="{ signItemed: item.status == 3 }" v-for="(item, index) in signInList" :key="item.id">
              <template v-if="item.status == 3">
                <svg-icon class="signed" iconClass="signed"></svg-icon>
                <div class="title"></div>
              </template>
              <div class="title" v-else>{{ item.prizes[0].name }}</div>
              <ImgDecypt class="prizesImg" :src="item.prizes[0].image" />
              <div class="day" :class="{ dayed: item.status == 3 }">{{ handSignInTitle(item, index) }}</div>
              <svg-icon class="lottery" iconClass="lottery"></svg-icon>
            </div>
          </div>
        </div>
        <div class="checkInBtn" @click="taskSing" v-if="!isTodaySigned"></div>
        <div class="checkedInBtn" @click="taskSinged" v-else></div>
      </div>
      <svg-icon class="dailyTasks" iconClass="dailyTasks" v-if="list.length > 0"></svg-icon>
      <div class="task_item" v-for="item in list" :key="item.id" @click="jumpDetails(item)">
        <div class="task_top">
          <div class="task_name">{{ item.title }}</div>
          <div class="task_info">
            <svg-icon class="fire" iconClass="fire"></svg-icon>
            <div class="hotNum">{{ item.hotValue | watchCountW }}</div>
            <svg-icon class="red_right_arrow" iconClass="redRightArrow"></svg-icon>
          </div>
        </div>
        <ImgDecypt class="task_image" :src="item.image" />
      </div>
    </div>
    <!-- 提示弹窗 -->
    <van-popup class="dialog" v-model="promptShow" :close-on-click-overlay="false">
      <div class="dialogContent">
        <div class="dialogTitle">
          <span>温馨提示</span>
        </div>
        <div class="dialogText">
          <span>绑定手机或充值VIP</span>
        </div>
        <div class="btnGroup">
          <img @click="$router.push('/bindPhone')" class="btn toBinding" src="@/assets/png/toBinding.png" />
          <img @click="$router.push('/memberCentre?t=vip')" class="btn toPrepaid" src="@/assets/png/toPrepaid.png" />
        </div>
      </div>
      <div class="closeBtn" @click="promptShow = false">
        <img src="@/assets/png/promptClose.png" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Tab, Tabs, Toast } from 'vant';
import ImgDecypt from '@/components/ImgDecypt';
// import PullRefresh from "@/components/PullRefresh";
import { queryTask, taskSing } from '@/api/mine';
export default {
  name: 'taskHall',
  components: {
    ImgDecypt,
    // PullRefresh,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      list: [], // 列表
      signInList: [], // 签到列表
      today: 0, // 当天
      grandTotal: 0, // 累计
      promptShow: false,
      atCheckInDay: undefined, // 再签到多少天获得VIP
      isTodaySigned: false, // 今天是否签到
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询列表
    async getList() {
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(queryTask);
        this.$store.commit('app/SET_LOADING', false);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          this.list = res.data.taskList;
          this.signInList = res.data.signDetails.list;
          this.today = res.data.signDetails.currentValue;
          this.grandTotal = res.data.signDetails.value;
          // 判断今日是否已签到
          let item = this.signInList[this.today - 1];
          if (item.status == 3) {
            this.isTodaySigned = true;
            return;
          }
        } else {
          Toast(res.tip || '查询失败');
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('查询失败');
      }
    },
    taskSinged() {
      Toast('你已签到');
    },
    // 签到
    async taskSing() {
      let item = this.signInList[this.today - 1]; // 已签到的
      if (item.status == 3) {
        Toast('你已签到');
        return;
      }
      let req = {
        id: item.id,
      };
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(taskSing, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res && res.code == 200) {
          this.getList();
          Toast(`恭喜你获得${item.prizes[0].name}`);
        } else {
          this.promptShow = true;
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('操作失败');
      }
    },

    handSignInTitle(item, index) {
      if (item.status == 3) {
        return '已签到';
      } else if (this.today == index + 1) {
        return '签到';
      }
      return item.title;
    },

    handNextPrizes() {
      if (this.signInList && this.signInList.length <= 0) {
        return;
      }
      let todayIndex = this.today - 1 < 0 ? 0 : this.today - 1;
      if (todayIndex == this.signInList.length - 1 && this.signInList[todayIndex].status == 3) {
        return '恭喜你已完成连续7天签到';
      } else if (this.signInList[todayIndex].status == 3) {
        return this.signInList[todayIndex + 1].prizes[0].desc;
      } else {
        return this.signInList[todayIndex].prizes[0].desc;
      }
    },
    // 跳转详情
    jumpDetails(item) {
      let data = {
        image: item.poster,
        desc: item.desc,
        type: item.type,
        id: item.id,
        rank: item.rank,
        rankType: item.rankType,
        isclosed: item.isclosed,
      };
      let dataStr = encodeURI(JSON.stringify(data));
      this.$router.push({
        path: '/taskDetails',
        query: { data: this.encodeBase64(dataStr) },
      });
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, 'utf-8').toString('base64');
      return base64Str;
    },
  },
};
</script>

<style lang="scss" scoped>
.taskHall {
  height: 100%;
  .navBar {
    height: 44px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    border-bottom: 0.7px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 116px;
    }
  }
  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 10px 12px;
    background-color: #fff;
    .signInCard {
      height: 379px;
      width: 351px;
      background: url('./../../../assets/png/checkInBg.png') no-repeat;
      background-size: 100% 100%;
      margin: 0px auto;
      position: relative;
      .signDesc {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 62px;
        font-size: 16px;
        font-weight: 800;
        color: #333333;
        span {
          font-size: 26px;
          color: rgb(255, 103, 143);
        }
      }
      .signBox {
        padding: 0 22px;
        position: absolute;
        top: 100px;
        .checkInBox {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          .signItem {
            height: 89px;
            width: 68px;
            background-color: rgb(255, 229, 236);
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            margin: 5px 0;
            position: relative;
            .signed {
              height: 32px;
              width: 34px;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 9;
            }
            .title {
              font-size: 12px;
              height: 13px;
              color: rgb(131, 137, 143);
            }
            .prizesImg {
              height: 34px;
              max-width: 60px;
              /deep/ .laz_com {
                background-color: unset;
                .vanImage {
                  background-color: unset;
                }
                .animationImg img {
                  width: unset;
                }
              }
            }
            .day {
              font-size: 14px;
              color: rgb(255, 103, 143);
            }
            .dayed {
              color: rgb(189, 141, 162);
            }
            .lottery {
              display: none;
            }
          }
          .signItem:last-of-type {
            width: 149px;
            position: relative;
            .title {
              display: none;
            }
            .prizesImg {
              height: 60px;
              max-width: 100px;
              position: absolute;
              left: 50%;
              top: 5px;
              transform: translate(-50%, 0);
            }
            .day {
              font-size: 14px;
              color: rgb(255, 103, 143);
              position: absolute;
              left: 14px;
              bottom: 3px;
            }
            .lottery {
              display: block;
              height: 20px;
              width: 38.5px;
              position: absolute;
              right: -5px;
              top: 5px;
            }
          }
          .signItemed {
            background: rgb(241, 243, 245);
          }
        }
      }
      .checkInBtn {
        height: 44px;
        width: 234px;
        background: url('./../../../assets/png/checkInBtn.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translate(-50%, 0);
      }
      .checkedInBtn {
        height: 44px;
        width: 234px;
        background: url('./../../../assets/png/checkedInBtn.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    .dailyTasks {
      height: 17px;
      width: 71px;
      margin: 20px 0 10px;
    }
    .task_item {
      width: 100%;
      height: 177px;
      padding: 0 10px;
      box-sizing: border-box;
      background: rgb(242, 245, 248);
      margin-bottom: 10px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .task_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        font-size: 15px;
        .task_info {
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgb(252, 105, 59);
          .fire {
            margin-right: 3px;
            height: 24px;
            width: 24px;
          }
          .hotNum {
            font-size: 14px;
          }
          .red_right_arrow {
            height: 20px;
            width: 20px;
          }
        }
      }
      .task_image {
        height: 126px;
        border-radius: 4px;
        overflow: hidden;
        /deep/ .vanImage {
          border-radius: 4px;
        }
      }
    }
    .task_item:last-of-type {
      margin-bottom: 20px;
    }
  }
}

.dialog {
  height: 4.43rem;
  border-radius: 0.2rem;
  background: none;

  .dialogContent {
    background: #ffffff;
    width: 7.04rem;
    height: 3.52rem;
    border-radius: 0.2rem;

    .dialogTitle {
      font-size: 0.4rem;
      color: rgb(132, 132, 132);
      display: flex;
      justify-content: center;
      padding-top: 0.3rem;
    }

    .dialogText {
      font-size: 0.38rem;
      color: rgb(100, 100, 100);
      padding-top: 0.32rem;
      font-weight: bold;
      text-align: center;

      .vip {
        font-size: 0.45rem;
        color: rgb(254, 127, 15);
      }
    }

    .btnGroup {
      width: 100%;
      display: flex;
      position: absolute;
      top: 2.1rem;
      display: flex;
      justify-content: center;

      .toBinding {
        //margin-left: 0.76rem;
      }

      .toPrepaid {
        margin-left: 0.22rem;
      }

      .btn {
        width: 2.76rem;
        height: 0.9rem;
      }
    }
  }

  .closeBtn {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 99999;

    img {
      width: 0.71rem;
      height: 0.71rem;
    }
  }
}
</style>
